/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '../Button/Button';
import { Divider } from '../Divider/Divider';
import banksData from '../../../data/banks.json';
import { InputField } from '../InputField/InputField';
import { CustomSelect, Spacer } from '../../atoms';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { DropdownIcon2, RedDeleteIcon } from '../../../assets/Icons';
import { FormCON, SettingsInfoCON, InputCON, InputExtraStyles, SubmitButtonCON, BankAccountsListCON } from './style';
import { BankDetails, BankDetailsResponse } from '../../../TYPES/api.body';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import ConfirmBankDetails from './confirmBankDetails';
import { QUERY_KEYS } from '../../../store/keys';
import { deleteBankDetails, getBankDetails } from '../../../network/services/wallet';
import { validators } from '../../../utils/validator';
import { useMutationHook } from '../../../network/mutations/useMutationHook';

type BankDetailsPartial = Omit<BankDetails, 'password'>;

const INITIAL_STATE: BankDetailsPartial = {
  bankName: '',
  accountName: '',
  accountNumber: ''
};

export const SettingsBankDetails = () => {
  const [formData, setFormData] = useState<BankDetailsPartial>(INITIAL_STATE);
  const [formError, setFormError] = useState<BankDetailsPartial>(INITIAL_STATE);
  const [userBankAccountsData, setUserBankAccountsData] = useState<BankDetailsResponse[]>([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const { refetch } = useQuery({
    queryKey: QUERY_KEYS.SELLER_BANK_DETAILS,
    queryFn: getBankDetails,
    onSuccess: ({ data }) => {
      // const lastData = data[data.length - 1];
      // setFormData(lastData || INITIAL_STATE);
      setUserBankAccountsData(data);
    },
    enabled: false
  });

  const { mutateAsync, isLoading } = useMutationHook(deleteBankDetails, 'DELETE_BANK_DETAILS');

  const handleDeleteBankDetails = async (accountNumber: string) => {
    try {
      await mutateAsync(accountNumber);
      refetch();
    } catch (error: any) {}
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;
    const error = validators[name](value);
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError({
      ...formError,
      [name]: error
    });
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const disabled =
    formData.bankName === '' ||
    formData.accountName === '' ||
    formData.accountNumber === '' ||
    formData.accountNumber.length < 10;

  const handleSuccess = () => {
    refetch();
    setFormData(INITIAL_STATE);
  };

  return (
    <SettingsInfoCON>
      <FormCON>
        <InputCON>
          <span>Bank Name</span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="bankName"
            value={formData.bankName}
            onChange={onSelectChange}
            placeholder="Select Bank"
            onClick={selectClick}>
            {banksData.map(bank => (
              <MenuItem key={bank.slug} value={bank.name}>
                {bank.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCON>
        <InputCON>
          <span>Account Name</span>
          <InputField
            extraStyles={InputExtraStyles}
            name="accountName"
            value={formData.accountName}
            placeholder="Enter account name"
            onChange={onInputChange}
          />
        </InputCON>

        <InputCON>
          <span>Account Number</span>
          <InputField
            type={'number'}
            extraStyles={InputExtraStyles}
            name="accountNumber"
            value={formData.accountNumber}
            error={formError.accountNumber}
            placeholder="Enter 10 digit account number"
            onChange={onInputChange}
          />
        </InputCON>
      </FormCON>

      <Spacer height="24px" />

      <BankAccountsListCON>
        <h4>Bank Accounts</h4>
        <Divider />
        {userBankAccountsData.map(bank => (
          <div className="bank-details-grid" key={bank?.bankName}>
            <p className="bank-detail">
              {bank?.bankName}: {bank?.accountName} - {bank?.accountNumber}
            </p>
            <span className="delete-icon">
              <RedDeleteIcon onClick={() => handleDeleteBankDetails(bank?.accountNumber)} />
            </span>
          </div>
        ))}
      </BankAccountsListCON>

      <Divider />
      <SubmitButtonCON>
        <Button disabled={disabled} onClick={() => setConfirmModal(true)}>
          Confirm
        </Button>
      </SubmitButtonCON>
      {confirmModal && (
        <ConfirmBankDetails
          bankDetails={formData}
          onSuccess={handleSuccess}
          setConfirmWithdrawModal={setConfirmModal}
        />
      )}
    </SettingsInfoCON>
  );
};
