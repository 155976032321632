/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from 'recoil';
import { Action, BrokenEyeIcon, BrokenGclose, CheckIcon, GreenDot } from '../../assets/Icons';
import { CustomPopover } from '../../components/atoms';
import { actionPopover } from '../../store/atoms/orderAtom';
import { ActionCON } from '../style';
// import { ActionCON, OrderDetailsCON, OrderDetailsContent, StatusCON } from '../style';
import { Modal } from '../../components/molecules/Modal/Modal';
import { viewOrderModal } from '../../store/atoms/modalAtoms';
import { useNavigate } from 'react-router-dom';
import { OrderDetailsCON, OrderDetailsContent, StatusCON } from '../../pages/Order/Details/style';
import { useState } from 'react';
// import { useQuery } from 'react-query';
// import { getSingleAddress } from '../../network/services/address';
// import { getOrderDetails } from '../../network/services/admin/order';

export const OrderActions = ({ order }: any) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);

  // const { data, isFetchedAfterMount } = useQuery(['admin_Order_details'], getOrderDetails);
  // const orderDetails = data?.data || {};

  const handleOpen = (event: any) => {
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const onViewDetailsSelect = () => {
    navigate(`/seller/orders/details/${order}`);
  };

  const options = [
    {
      label: `View details`,
      value: 'View details',
      Icon: BrokenEyeIcon,
      handleAction: onViewDetailsSelect
    }

    // {
    //   label: 'Accept',
    //   value: 'Accept',
    //   Icon: CheckIcon,
    //   color: '#27AE60',
    //   handleAction: () => {}
    // }
  ];

  return (
    <ActionCON aria-describedby="order-actions" onClick={event => handleOpen(event)}>
      <Action />
      <CustomPopover item={order} id="order-actions" open={value} options={options} />
    </ActionCON>
  );
};
