import { useNavigate, useParams } from 'react-router-dom';
import { COLORS } from '../../../theme/color';
import { orderProduct } from '../demoData';
import { OrderDetailsCon, OrderInfo, OrderLabel, OrderSection } from './styles';
import { HeaderCON } from '../styles';
import { CircularProgress, Divider } from '@mui/material';
import { EmptyCon, OrderButton } from '../../../components/atoms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { changeOrderStatus, getSellerOrder } from '../../../network/services/orders';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

export const OrderDetails = () => {
  const go = useNavigate();
  const { id } = useParams();

  const queryClient = useQueryClient();
  const { data: order, isFetchedAfterMount: orderIsFetchedAfterMount } = useQuery(
    ['seller_recent_orders', { id: id }],
    getSellerOrder,
    { staleTime: Infinity, enabled: !!id }
  );

  // Mutation for changing the order status
  const { mutate: changeStatus, isLoading: isChangingStatus } = useMutation(changeOrderStatus, {
    onSuccess: () => {
      enqueueSnackbar('Order status updated successfully!', {
        variant: 'success',
        autoHideDuration: 2000
      });
      // Invalidate the query for the specific order
      queryClient.invalidateQueries(['seller_recent_orders', { id }]);
    },
    onError: error => {
      // Handle error
      enqueueSnackbar(`${error}`, { variant: 'error', autoHideDuration: 4000 });
    }
  });

  const item = order?.data;

  const formatReadableDateTime = (isoString: string): string => {
    const date = new Date(isoString);

    // Format the date and time
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true // Set to false for 24-hour format
    };

    return date.toLocaleString(undefined, options);
  };
  const formatNumber = (num: any) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(num);
  };

  const handleApprove = () => {
    changeStatus({ orderId: id!, status: 'approved' });
  };

  const track = () => go('track');
  return (
    <>
      <HeaderCON>Order Details</HeaderCON>
      {!orderIsFetchedAfterMount && (
        <EmptyCon>
          <CircularProgress size={30} />
        </EmptyCon>
      )}
      {orderIsFetchedAfterMount && (
        <OrderDetailsCon>
          <div className="id">
            <p>Order {item.id}</p>
          </div>
          <div className="text1">{item.orderItemQuantity} Item(s)</div>
          <div className="text1">{formatReadableDateTime(item.orderDate)}</div>
          <div className="div1 cancel">
            Total: N{formatNumber(item.totalAmount)}
            <div className="grid gap-3">
              {item.orderStatus === 'ONGOING' && (
                <OrderButton outlined color={COLORS.MainBlue} onClick={handleApprove}>
                  APPROVE
                </OrderButton>
              )}

              <OrderButton bgColor={item.orderStatus === 'PENDING' ? '#F04438' : COLORS.MainBlue}>
                {item.orderStatus}
              </OrderButton>
            </div>
          </div>
          <Divider />
          <div className="div1">
            Items in this order{' '}
            {/* <OrderButton outlined color={COLORS.MainBlue} onClick={track}>
              Track History
            </OrderButton> */}
          </div>
          <Divider />
          {item?.products?.map((product: any, index: number) => (
            <OrderSection key={index}>
              <div className="item">
                <img src={product?.images[0]} alt="order product" />
                <div className="text">
                  <div>
                    <div className="title">{product.productName}</div>
                    <div className="qty">QTY: {product.quantity}</div>
                  </div>
                  <div className="amount">N{formatNumber(product.price)}</div>
                </div>
              </div>
            </OrderSection>
          ))}
          {/* <OrderInfo>
            <div className="col">
              <div className="headings">PAYMENT INFORMATION</div>
              <div className="text-con">
                <div className="method">Payment Method</div>
                <div>Mastercard ending in **** 1492</div>
                <div>Payment Details</div>
                <div>Items: N35,000</div>
                <div>Shipping: N1,500</div>
                <div>Total: N37,000</div>
              </div>
            </div>
            <div className="col">
              <div className="headings">DELIVERY INFORMATION</div>
              <div className="text-con">
                <div className="method">Delivery Method</div>
                <div>Home delivery</div>
                <div>Delivery Details</div>
                <div>Billsonme</div>
                <div>Uba headquaters Marina Lagos</div>
                <div>Expected Delivery Date on 22/12/2022</div>
              </div>
            </div>
          </OrderInfo> */}
        </OrderDetailsCon>
      )}
    </>
  );
};
