import { GridRenderCellParams } from '@mui/x-data-grid';
import { SellerProductActions } from '../../../actions/Seller/Product';
import { BrokenGraph, BrokenBox, BrokenGclose, AdminUsersIcon } from '../../../assets/Icons';
// import { BrokenGraph, BrokenBox, BrokenGclose, AdminUsersIcon, RedDot, GreenDot } from '../../../assets/Icons';
import { RedDot, GreenDot } from '../../../assets/Icons';
import { formatCurrency } from '../../../utils/currencyFormater';
import { truncateCharCount } from '../../../utils/truncate';
import { ActionCON, ProductStatusCON, ProductImgCon } from './style';
export const topCardData = [
  {
    title: 'No of products',
    total: 338,
    Icon: BrokenGraph,
    iconBg: '#F5FBEF'
  },
  {
    title: 'Products Out of Stock',
    total: 0,
    Icon: BrokenBox,
    iconBg: '#FEF9EC'
  },

  {
    title: 'Total Customers',
    total: 0,
    Icon: AdminUsersIcon,
    iconBg: '#EFF1FB'
  },

  {
    title: 'Cart Abandonment',
    total: 0,
    Icon: BrokenGclose,
    iconBg: '#FFF0F3'
  }
];

export const columns = [
  {
    id: 1,
    field: 'S/N',
    flex: 1,
    minWidth: 30,
    // renderCell: ({ id }: any) => {
    //   return <p>{id}</p>;
    // }
    renderCell: (params: GridRenderCellParams) => params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
  },

  {
    id: 1,
    headerName: 'Product Name',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <ActionCON>
        <ProductImgCon>
          <img src={row.images[0]} alt={row?.images[0]} />
          <p>{truncateCharCount(row.productName, 100)}</p>
        </ProductImgCon>
      </ActionCON>
    )
  },

  {
    id: 2,
    field: 'subCategoryName',
    headerName: 'Sub Category Name',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row.subCategoryName}</p>
  },

  {
    id: 3,
    field: 'quantityAvailable',
    headerName: 'Quantity',
    flex: 1,
    minWidth: 100
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatCurrency(row.price)}</p>
  },

  {
    id: 5,
    field: 'status',
    headerName: 'status',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <ProductStatusCON status={row.status}>
        {row?.status?.toLowerCase()?.includes('in-stock') ? <GreenDot /> : <RedDot />}
        <p>{row?.status}</p>
      </ProductStatusCON>
    )
  },

  {
    id: 6,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => {
      return <SellerProductActions product={row.id} />;
    }
  }
];
