import { DashboardHeader, TopHeaderCard } from '../../components/molecules';
import { newOrderColumn, orderColumns } from './data';
import { OrderCON, OrderTableCON } from './style';
import { CustomTable, Spacer } from '../../components/atoms';
import { BrokenGraph, BrokenGclose, AdminUsersIcon, BoxAdd } from '../../assets/Icons';
import { useQuery } from 'react-query';
import { getSellerOrders, getSellerOrdersAnalytics } from '../../network/services/orders';
import { useState } from 'react';

export const OrderList = () => {
  const [page, setPage] = useState(1);
  const pageSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE ? parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) : 10;

  const { data: ordersData, isFetchedAfterMount } = useQuery(
    ['seller_orders', { page: page - 1, size: pageSize }],
    getSellerOrders
  );

  const sellerOrdersData = ordersData?.data?.filter((order: any) => order && Object.keys(order).length) || [];
  const orders = sellerOrdersData.map((order: any) => ({
    ...order,
    productName: order.productName || 'Unknown'
  }));

  const { data: analyticsData } = useQuery(['seller_orders_analytics'], getSellerOrdersAnalytics, {
    staleTime: Infinity,
    retry: false
  });
  const analytics = analyticsData?.data || {};

  const topCardData = [
    { title: 'All Orders', total: analytics?.all, Icon: BrokenGraph, iconBg: '#F5FBEF' },
    { title: 'New Orders', total: analytics?.newOrder, Icon: BoxAdd, iconBg: '#FEF9EC' },
    { title: 'Completed Orders', total: analytics?.completed, Icon: AdminUsersIcon, iconBg: '#EFF1FB' },
    { title: 'Pending Orders', total: analytics?.pending, Icon: BrokenGclose, iconBg: '#FFF0F3' },
    { title: 'Canceled Orders', total: analytics?.cancelled, Icon: BrokenGclose, iconBg: '#FFF0F3' }
  ];

  return (
    <OrderCON>
      <DashboardHeader title="Orders" />
      <TopHeaderCard data={topCardData.slice(0, 6)} />
      <Spacer height="48px" />
      {orders && (
        <OrderTableCON>
          <CustomTable
            isLoading={isFetchedAfterMount}
            columns={newOrderColumn}
            data={orders}
            totalItems={ordersData?.totalElements ?? 0}
            totalPages={ordersData?.totalPage ?? 0}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </OrderTableCON>
      )}
    </OrderCON>
  );
};
