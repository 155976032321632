import styled from '@emotion/styled';
import { breakpoints, themes } from '../../../theme/theme';

export const TopHeaderCON = styled('div')<{ gap?: string }>`
  display: flex;
  width: 100%;
  margin: 28px 0px 0px 0px;
  background: white;
  border-radius: 12px;
  padding: 24px 24px;
  align-items: start;
  gap: ${({ gap }) => gap};
  justify-content: ${({ gap }) => (gap ? 'normal' : 'space-between')};
  .carousel-con {
    width: 100%;
    height: auto;
  }

  .react-multi-carousel-dot-list {
    bottom: 0;
  }

  .react-multi-carousel-dot button {
    border-color: #d9d9d9;
    background: #d9d9d9;
  }

  .react-multi-carousel-dot--active button {
    background: ${themes.palette.primary.main};
    border-color: ${themes.palette.primary.main};
  }

  @media (max-width: ${breakpoints.md}px) {
    background: none;
    padding: 0px;
    margin: 0px;
  }
`;

export const IconCon = styled('div')<{ color?: string }>`
  background: ${({ color }) => color};
  border-radius: 8px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const CardCON = styled('div')`
  display: flex;
  align-items: start;

  gap: 40px;
  @media (max-width: ${breakpoints.md}px) {
    margin-right: 20px;
    background: white;
    margin: 28px 0px 50px 0px;
    padding: 24px 24px;
    border-radius: 12px;
    min-height: 140px;
  }

  p {
    color: #483d3d;
    font-size: 16px;
    font-weight: 500px;
  }

  h3 {
    padding-top: 12px;
    color: #483d3d;
    font-size: 24px;
    font-weight: 700px;
  }
`;

export const CardDivider = styled('div')`
  width: 0px;
  height: 88px;
  outline: 0.8px solid #e9e5e5;
  margin: 2px 0 2px 11.5px;
`;

export const PercentageCON = styled('div')<{ percentage?: string }>`
  background: ${({ percentage }) => (percentage?.includes('+') ? '#F0F5EA' : '#FEECEB')};
  color: ${({ percentage }) => (percentage?.includes('+') ? '#669F2A' : '#F04438')};
  border-radius: 100px;
  font-size: 13px;
  margin-top: 5px;
  display: flex;
  padding: 4px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
