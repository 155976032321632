import { ChangeEvent, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { HidePassword, ShowPassword, UploadIcon } from '../../../assets/Icons';
import { Button, Container, Spacer } from '../../../components/atoms';
import { InputField } from '../../../components/atoms/InputField/InputField';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { register } from '../../../network/services/auth';
import { Tooltip } from '@chakra-ui/react';
import { validators } from '../../../utils/validator';
import {
  AltSignUp,
  AuthContainer,
  CustomSelect,
  extraStylesInput,
  extraStylesInputPhone,
  InputCon,
  TogglePassword,
  UploadImageCon,
  buttonStyle
} from '../styles';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { getAllcategories } from '../../../network/services/goods';
import { useQuery } from 'react-query';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import SELLER_LOCATIONS from './locations';
import { IRegisterSeller, ResponseSuccess } from '../../../TYPES/api.body';
import { validateAddressDeliveryCallback } from '../../../network/services/deliveries';
import { removeNonAlphabets } from '../../../utils/removeNonAlphabet';

const INITIALSTATE: IRegisterSeller = {
  street: '',
  business_name: '',
  cac: '',
  categoryName: '',
  confirm_password: '',
  email: '',
  businessDescription: '',
  state: SELLER_LOCATIONS[0],
  password: '',
  phoneNumber: '',
  brandImage: new File([], ''),
  brandLogo: new File([], ''),
  governmentId: new File([], ''),
  utilityBill: new File([], '')
};

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

export const SignUp = () => {
  const { data: categoriesData } = useQuery('categories', getAllcategories);
  const categories = categoriesData || [];

  const [formData, setFormData] = useState(INITIALSTATE);
  const [formError, setFormError] = useState(INITIALSTATE);
  const [agreement, setAgreement] = useState(false);
  const [utilityBillError, setUtilityBillError] = useState<string>('');
  const [governmentIdError, setGovernmentIdError] = useState<string>('');
  const governmentIdRef = useRef<HTMLInputElement | null>(null);
  const utitlityBiilRef = useRef<HTMLInputElement | null>(null);
  const [brandImageError, setBrandImageError] = useState<string>('');
  const [brandLogoError, setBrandLogoError] = useState<string>('');
  const brandImageRef = useRef<HTMLInputElement | null>(null);
  const brandLogoRef = useRef<HTMLInputElement | null>(null);
  const onToggleAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const onUpdateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let error = validators[name](value);

    // Custom validation for confirm_password
    if (name === 'confirm_password' && value !== formData.password) {
      error = 'Passwords do not match';
    }
    setFormData(formData => ({ ...formData, [name]: value }));
    setFormError(formError => ({ ...formError, [name]: error }));
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };
  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };
  const onToggleConfirmPassword = () => {
    if (showConfirmPassword === 'text') return setShowConfirmPassword('password');
    setShowConfirmPassword('text');
  };

  const { mutateAsync, isLoading } = useMutationHook<any, ResponseSuccess>(register, 'SIGNUP');

  const {
    mutateAsync: validateAddress,
    isLoading: validatingAddress,
    data: validateAddressData
  } = useMutationHook<any, ResponseSuccess>(validateAddressDeliveryCallback, 'VALIDATESHIPBUBBLEADDRESS');
  const removePrefix234 = (input: string): string => {
    // Remove prefix '234' if it exists
    const withoutPrefix = input.startsWith('234') ? input.slice(3) : input;

    // Add '0' to the front if the remaining number is 10 digits
    return withoutPrefix.length === 10 ? `0${withoutPrefix}` : withoutPrefix;
  };

  const ensureLastName = (name: string): string => {
    // Check if there's a space in the name
    return name.includes(' ') ? name : `${name} Seller`;
  };
  const _register = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const sellerAddrPromise = validateAddress({
      name: removeNonAlphabets(ensureLastName(formData?.business_name)),
      email: formData?.email,
      phone: removePrefix234(formData?.phoneNumber),
      address: `${formData?.street}, ${formData?.state}, Nigeria`
    });

    // If validation fails, stop the process
    if (!sellerAddrPromise || (await sellerAddrPromise).status !== 'SUCCESS') {
      // Show an error message or handle failure
      console.error('Address validation failed');
      return;
    }
    const payload = new FormData();

    // Append each form field individually
    Object.entries(formData).forEach(([key, value]) => {
      if (value instanceof File) {
        if (value.name) payload.append(key, value); // Only append files if they have a name
      } else {
        payload.append(key, value as string);
      }
    });

    payload.append('country', 'Nigeria');
    // @ts-ignore
    payload.append('addressCode', validateAddressData?.data?.address_code);

    try {
      const data = await mutateAsync(payload);
      if (data.data.status === 'success') {
        navigate(`/otp/${formData.email}`);
      }
    } catch (error) {}
  };

  const handleFileRead = async (e: COMMONTYPES['inputChange']) => {
    const file = e.target.files?.[0];
    const { name } = e.target;

    if (file && file.size > MAX_FILE_SIZE) {
      if (name === 'utilityBill') setUtilityBillError('File size exceeds 3MB');
      if (name === 'governmentId') setGovernmentIdError('File size exceeds 3MB');
      if (name === 'brandImage') setBrandImageError('File size exceeds 3MB');
      if (name === 'brandLogo') setBrandLogoError('File size exceeds 3MB');
      return;
    }

    if (file && !ALLOWED_FILE_TYPES.includes(file.type)) {
      if (name === 'utilityBill') setUtilityBillError('Only JPG and PNG files are allowed');
      if (name === 'governmentId') setGovernmentIdError('Only JPG and PNG files are allowed');
      if (name === 'brandImage') setBrandImageError('Only JPG and PNG files are allowed');
      if (name === 'brandLogo') setBrandLogoError('Only JPG and PNG files are allowed');
      return;
    }

    if (name === 'utilityBill' && file) {
      setFormData({ ...formData, utilityBill: file });
      setUtilityBillError('');
    }
    if (name === 'governmentId' && file) {
      setFormData({ ...formData, governmentId: file });
      setGovernmentIdError('');
    }
    if (name === 'brandImage' && file) {
      setFormData({ ...formData, brandImage: file });
      setBrandImageError('');
    }
    if (name === 'brandLogo' && file) {
      setFormData({ ...formData, brandLogo: file });
      setBrandLogoError('');
    }
  };

  const isUtilityBillUploaded = formData.utilityBill.name !== '';
  const isGovernmentIdUploaded = formData.governmentId.name !== '';
  const isBrandImageUploaded = formData.brandImage.name !== '';
  const isBrandLogoUploaded = formData.brandLogo.name !== '';

  const disabled =
    !formData.email ||
    !formData.password ||
    !formData.phoneNumber ||
    !formData.categoryName ||
    !formData.businessDescription ||
    !!validators.email(formData.email) ||
    formData.password !== formData.confirm_password || // Ensure confirm_password matches
    !!validators.password(formData.password) ||
    !!formError.confirm_password ||
    !isUtilityBillUploaded ||
    !isGovernmentIdUploaded ||
    !isBrandImageUploaded ||
    !isBrandLogoUploaded ||
    !!brandLogoError ||
    !!brandImageError ||
    !!utilityBillError ||
    !!governmentIdError;

  return (
    <AuthContainer>
      <div>
        <h1 className=" ">Create an account</h1>
        <p className="option ">
          Already have an account? <span onClick={() => navigate('/')}>Log in</span>
        </p>
        <Spacer height="1.5rem" />
        <Container>
          <form className="sign-up">
            <AltSignUp>
              <InputCon>
                <span>Business Name</span>
                <InputField
                  name="business_name"
                  onChange={onUpdateFormData}
                  placeholder="Enter a business name"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.business_name}
                />
              </InputCon>
              <Spacer height="2rem" width="2rem" />
              <InputCon>
                <span>Email Address</span>
                <InputField
                  type="email"
                  name="email"
                  onChange={onUpdateFormData}
                  placeholder="example@mail.com"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.email}
                  autoComplete="username"
                />
              </InputCon>
            </AltSignUp>
            <Spacer height="1.5rem" />
            <AltSignUp>
              <InputCon>
                <span>Phone Number</span>
                <PhoneInput
                  country={'ng'}
                  value={formData.phoneNumber}
                  onChange={(value: string) => {
                    setFormData(formData => ({ ...formData, phoneNumber: value }));
                  }}
                  buttonStyle={buttonStyle}
                  placeholder={'80XXXXXXXX'}
                  autoFormat={false}
                  inputStyle={extraStylesInputPhone}
                />
              </InputCon>
              <Spacer height="2rem" width="2rem" />
              <InputCon>
                <span>Category</span>
                <CustomSelect
                  name="categoryName"
                  value={formData.categoryName}
                  onChange={onSelectChange}
                  onClick={selectClick}>
                  {categories?.map(({ id, name }: any) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </InputCon>
            </AltSignUp>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Brief Description</span>
              <InputField
                name="businessDescription"
                onChange={onUpdateFormData}
                placeholder="Briefly describe your business in one sentence"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.businessDescription}
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>CAC Reg Number</span>
              <InputField
                type="text"
                name="cac"
                onChange={onUpdateFormData}
                placeholder="YUGJHGH877"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.cac}
              />
            </InputCon>
            <Spacer height="1.5rem" />
            <InputCon>
              <span>Business Street</span>
              <InputField
                name="street"
                onChange={onUpdateFormData}
                placeholder="Enter your business street"
                extraStyles={extraStylesInput}
                required={true}
                error={formError.street}
              />
            </InputCon>
            <Spacer height="1.5rem" />

            <InputCon>
              <span>Business State</span>
              <CustomSelect name="state" value={formData.state} onChange={onSelectChange} onClick={selectClick}>
                {SELLER_LOCATIONS.map((state: string, index: number) => (
                  <MenuItem key={state.toLowerCase()} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </CustomSelect>
            </InputCon>
            <Spacer height="1.5rem" />

            <AltSignUp>
              <InputCon>
                <span>Password</span>
                <InputField
                  type={showPassword}
                  name="password"
                  onChange={onUpdateFormData}
                  placeholder="enter your password"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.password}
                  action={
                    <TogglePassword onClick={onTogglePassword}>
                      {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                    </TogglePassword>
                  }
                  autoComplete="new-password"
                />
              </InputCon>
              <Spacer height="2rem" width="2rem" />
              <InputCon>
                <span>Confirm Password</span>
                <InputField
                  type={showConfirmPassword}
                  name="confirm_password"
                  onChange={onUpdateFormData}
                  placeholder="confirm your password"
                  extraStyles={extraStylesInput}
                  required={true}
                  error={formError.confirm_password}
                  action={
                    <TogglePassword onClick={onToggleConfirmPassword}>
                      {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                    </TogglePassword>
                  }
                  autoComplete="new-password"
                />
              </InputCon>
            </AltSignUp>
            <Spacer height="1.5rem" />

            <AltSignUp>
              <UploadImageCon>
                <span>Brand Logo</span>
                <div onClick={() => brandLogoRef.current?.click()}>
                  <p>{formData.brandLogo.name || 'Upload Document'}</p>
                  <UploadIcon />
                  <input type="file" ref={brandLogoRef} name="brandLogo" onChange={handleFileRead} required={true} />
                </div>
              </UploadImageCon>
              {brandLogoError && <p className="error">{brandLogoError}</p>}
              <Spacer height="2rem" width="2rem" />

              <UploadImageCon>
                <p className="flex justify-between items-center">
                  <span>Cover Image</span>
                  <Tooltip
                    hasArrow
                    bg="#444444"
                    color="#ffffff"
                    label="Upload a high-quality image of your best products for customers ."
                    aria-label="A tooltip"
                    placement="top-start"
                    className="px-4 py-1 rounded-lg">
                    <span className=" text-blue-600 text-[12px]">What is this? </span>
                  </Tooltip>
                </p>

                <div onClick={() => brandImageRef.current?.click()}>
                  <p>{formData.brandImage.name || 'Upload Document'}</p>
                  <UploadIcon />
                  <input type="file" ref={brandImageRef} name="brandImage" onChange={handleFileRead} required={true} />
                </div>
              </UploadImageCon>

              {brandImageError && <p className="error">{brandImageError}</p>}
            </AltSignUp>
            <Spacer height="1.5rem" />
            <AltSignUp>
              <UploadImageCon>
                <span>Utility Bill</span>
                <div onClick={() => utitlityBiilRef.current?.click()}>
                  <p>{formData.utilityBill.name || 'Upload Document'}</p>
                  <UploadIcon />
                  <input
                    type="file"
                    ref={utitlityBiilRef}
                    name="utilityBill"
                    onChange={handleFileRead}
                    required={true}
                  />
                </div>
              </UploadImageCon>
              {utilityBillError && <p className="error">{utilityBillError}</p>}
              <Spacer height="2rem" width="2rem" />
              <UploadImageCon>
                <span>Government Issued ID</span>
                <div onClick={() => governmentIdRef.current?.click()}>
                  <p>{formData.governmentId.name || 'Upload Document'}</p>
                  <UploadIcon />
                  <input
                    type="file"
                    ref={governmentIdRef}
                    name="governmentId"
                    onChange={handleFileRead}
                    required={true}
                  />
                </div>
              </UploadImageCon>
              {governmentIdError && <p className="error">{governmentIdError}</p>}
            </AltSignUp>

            <Spacer height="1.5rem" />

            <div className="policy">
              <input type="checkbox" name="policy" id="policy" onChange={onToggleAgreement} />
              <p className="option">
                I have read, understood and I agree to Madina’s<span> Privacy Policy</span>, and{' '}
                <span>Terms and conditions</span>.
              </p>
            </div>

            <div className="buttonContainer">
              <Button
                isLoading={isLoading}
                type="submit"
                onClick={_register}
                disabled={isLoading || disabled || !agreement}>
                Create Account
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </AuthContainer>
  );
};
